<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_allOrders',
          'consolidations_USAFedex',
          'consolidations_USADHL',
          'consolidations_consolidationEU',
          'consolidations_consolidationEUProduct',
          'consolidations_yourCity',
        ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
           v-bind:class="{'loading-tabs': $store.getters.getConsolidationLoading === true,}">

        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index + loadUserRoleAndAuthorizedData"
               v-bind:class="{active: item.active}"
               v-if="checkUserFromSNGGroup(item) && item.permissionVisible"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{item.label}}
              </template>
            </div>
            <span class="content-tabs__item-small">
              {{item.subLabel}}
            </span>
          </div>
        </template>

        <template v-if="isUserHasEasyOrder">
          <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '?&type=FBM'" class="content-tabs__item">
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                <!--                {{ $t(item.label + '.localization_value.value')}}-->
                {{ $t('common_SelfShipping.localization_value.value') }}
              </template>
            </div>
          </router-link>
          <router-link :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '?&type=Returns'" class="content-tabs__item">
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <!--              <span class="admin-edit" @click="editTranslate([item.label])"></span>-->
              <template>
                <!--                {{ $t(item.label + '.localization_value.value')}}-->
                {{ $t('ordersInbound_Returns.localization_value.value') }}
              </template>
            </div>
          </router-link>
        </template>

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i">
          <DefaultCheckbox
              :label="$t('common_selectAll.localization_value.value')"
              @input="(val) => $emit('selectAll', val)"
          />
        </div>
      </div>

      <div class="btn-right-block">
        <MainButton class="btn-fit-content ml-2"
                    v-if="navTabs.all && navTabs.all.active"
                    :value="$t('common_AddNew.localization_value.value')"
                    :ico="true"
                    @click.native="changeChooseExpressOrderPopup(true)"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
        <router-link
            v-else-if="navTabs[ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT] && navTabs[ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT].active"
            :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT + '?initStorage=' + LODZ_WAREHOUSE.storage_id"
        >
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
<!--        <template v-if="activeTabType === ORDERS_CONSOLIDATION_TYPE.DHL_USA && (isUkrainian || isUserAlternativeContactUkraine)"></template>-->
        <router-link
            v-else-if="activeTabType !== ORDERS_CONSOLIDATION_TYPE.DHL_USA && !(isLatvian && activeTabType === ORDERS_CONSOLIDATION_TYPE.FEDEX)"
            :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + activeTabType"
        >
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
    <ChooseConsolidationOrderPopup
      v-if="isModalChooseExpressOrderPopup"
      :navTabs="navTabs"
      :euProductsTab="navTabs[ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT] && navTabs[ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT].active"
      @close="changeChooseExpressOrderPopup(false)"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import ChooseConsolidationOrderPopup from "../../../../popups/ChooseConsolidationOrderPopup/ChooseConsolidationOrderPopup";

  import {
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE,
    ORDERS_CONSOLIDATION_TYPE, LODZ_WAREHOUSE
  } from "../../../../../../../staticData/staticVariables";


  export default {
    name: "ConsolidationHead",
    components: {
      MainButton,
      ToggleFilterButton,
      DefaultCheckbox,
      PlusIconSVG,
      ChooseConsolidationOrderPopup,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    created() {

    },

    computed: {
      activeTabType() {
        return this._.find(this.navTabs, {active: true})?.name
      },
    },

    data() {
      return {
        isShowSearchEngineBlock: false,
        isModalChooseExpressOrderPopup: false,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
        LODZ_WAREHOUSE: LODZ_WAREHOUSE,
      }
    },



    methods: {

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeChooseExpressOrderPopup(val){
        this.isModalChooseExpressOrderPopup = val
      },

      checkUserFromSNGGroup(item) {
        if(this.isAdmin) return true

        let user = this.$store.getters.getUserProfile,
            group = user.user_personal_contact?.country_group?.fromSNGGroup

        return !(group && (item.id === DHL_DELIVERY_SERVICE.id || !item.id))
      },

    }
  }
</script>

<style scoped>
  @media (min-width: 680px) {
    .btn-left-block{
      flex-wrap: wrap;
      max-width: 50%;
    }

    .btn-left-block-i{
      margin-left: 0!important;
      margin-right: 15px;
    }
  }

  @media (max-width: 680px){
    .btn-right-block{
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .btn-right-block .btn-right-block-i{
      /*margin-top: 15px;*/
      margin-right: 0!important;
    }

    .btn-right-block .btn-right-block-i:last-child{
      margin-top: 0;
    }

    .btn-left-block{
      display: flex;
      flex-direction: column;
      max-width: 50%
    }

    .btn-left-block-i{
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .btn-left-block-i:last-child{
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn-left-block-i--link{
      font-size: 12px;
    }

    .light-bg-block{
      align-items: flex-end;
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
</style>

